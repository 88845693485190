<app-detail-main-template>
  <div container-header>
    <app-detail-header-template>
      <div top-left>
        <div class="back-btn-container">
          <app-back-button></app-back-button>
        </div>
        <h3 class="title-container bold">{{projectText | uppercase}}</h3>
      </div>
      <div bottom-left>
        <div class="sub-menu__container--project-config">
          <div class="item">
            <a class="link"
               [ngClass]="{'active': selectedTab === 'project', 'disabled': !enableProjectConfigSave}"
               (click)="toggleTab('project')">
              <div class="button">
                <div class="description">Project Settings</div>
              </div>
            </a>
          </div>
          <div *ngIf="!isChartLakeProject" class="sub-menu__container--chart-lake">
            <div class="item">
              <a class="link"
                 [ngClass]="{'active': selectedTab === 'sla', 'disabled': !enableProjectConfigSave}"
                 (click)="toggleTab('sla')">
                <div class="button">
                  <div class="description">Milestones</div>
                </div>
              </a>
            </div>
            <div *ngIf="isHedisProject" class="item">
              <a class="link"
                 [ngClass]="{'active': selectedTab === 'bot', 'disabled': !enableProjectConfigSave}"
                 (click)="toggleTab('bot')">
                <div class="button">
                  <div class="description">EVE Settings</div>
                </div>
              </a>
            </div>
            <div *ngIf="isRiskProject || isIvaProject" class="item">
              <a class="link"
                 [ngClass]="{'active': selectedTab === 'riskBot', 'disabled': !enableProjectConfigSave}"
                 (click)="toggleTab('riskBot')">
                <div class="button">
                  <div class="description">EVE Settings</div>
                </div>
              </a>
            </div>
            <div *ngIf="isHedisProject" class="item">
              <a class="link"
                 [ngClass]="{'active': selectedTab === 'hedis', 'disabled': !enableProjectConfigSave}"
                 (click)="toggleTab('hedis')">
                <div class="button">
                  <div class="description">HEDIS Settings</div>
                </div>
              </a>
            </div>
            <div *ngIf="isRiskProject || isIvaProject" class="item">
              <a class="link"
                 [ngClass]="{'active': selectedTab === 'risk', 'disabled': !enableProjectConfigSave}"
                 (click)="toggleTab('risk')">
                <div class="button">
                  <div class="description">{{riskIvaHeader}}</div>
                </div>
              </a>
            </div>
            <div class="item">
              <a class="link"
                 [ngClass]="{'active': selectedTab === 'packet', 'disabled': !enableProjectConfigSave}"
                 (click)="toggleTab('packet')">
                <div class="button">
                  <div class="description">Provider Packet</div>
                </div>
              </a>
            </div>
            <div class="item">
              <a class="link"
                 [ngClass]="{'active': selectedTab === 'invoice', 'disabled': !enableProjectConfigSave}"
                 (click)="toggleTab('invoice')">
                <div class="button">
                  <div class="description">Invoices</div>
                </div>
              </a>
            </div>
            <div class="item">
              <a class="link"
                 [ngClass]="{'active': selectedTab === 'reporting', 'disabled': !enableProjectConfigSave}"
                 (click)="toggleTab('reporting')">
                <div class="button">
                  <div class="description">Reporting</div>
                </div>
              </a>
            </div>
            <div *ngIf="isDataExtractVisible" class="item">
              <a class="link"
                 [ngClass]="{'active': selectedTab === 'dataExtract', 'disabled': !enableProjectConfigSave}"
                 (click)="toggleTab('dataExtract')">
                <div class="button">
                  <div class="description">Data Extract</div>
                </div>
              </a>
            </div>
            <div class="item">
              <a class="link"
                 [ngClass]="{'active': selectedTab === 'checklist', 'disabled': !enableProjectConfigSave}"
                 (click)="toggleTab('checklist')">
                <div class="button">
                  <div class="description description-checklist">Data Entry Checklist</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </app-detail-header-template>
  </div>
  <div container-body>
    <form [formGroup]="projectConfigForm">
      <div formGroupName="projectSettingsFormGroup"
           class="settings-container"
           [hidden]="selectTab('project')">
        <div class="packet-container packet-container__label">GENERAL SETTINGS</div>
        <div class="attribute-container">
          <div class="attribute-text">
            <form-factory [formGroup]="projectSettingsFormGroup"
                          [model]="projectConfigurationName"></form-factory>
          </div>
        </div>
        <div class="attribute-container">
          <div class="attribute-text">
            <form-factory [formGroup]="projectSettingsFormGroup"
                          [model]="projectConfigurationStatus"
                          (onChange)="alertProjectActivation($event)"></form-factory>
            <app-modal [(visible)]="showConfirmationForProjectStatusChange"
                       header="WARNING"
                       rejectText="CLOSE">
              {{confirmationTextForChangingProjectStatus}}
            </app-modal>
          </div>
        </div>
        <div class="attribute-container">
          <div class="attribute-text dropdown">
            <form-factory [formGroup]="projectSettingsFormGroup"
                          [model]="projectOwner"></form-factory>
          </div>
        </div>
        <div class="attribute-container">
          <div class="attribute-text dropdown">
            <form-factory [formGroup]="projectSettingsFormGroup"
                          [model]="reveleerProjectContacts"></form-factory>
          </div>
        </div>
        <div *ngIf="!isChartLakeProject">
          <div class="attribute-container">
            <div class="attribute-text">
              <form-factory [formGroup]="projectSettingsFormGroup"
                            [model]="clinicalGuidelinesSignOffDate"></form-factory>
            </div>
          </div>
          <div class="attribute-container">
            <div class="attribute-text">
              <form-factory [formGroup]="projectSettingsFormGroup"
                            [model]="documentRequestLinesApprovalDate"></form-factory>
            </div>
          </div>
          <div class="attribute-container">
            <div class="attribute-text">
              <form-factory [formGroup]="projectSettingsFormGroup"
                            [model]="providerLetterReceivedDate"></form-factory>
            </div>
          </div>
          <div class="attribute-container">
            <div class="attribute-text">
              <form-factory [formGroup]="projectSettingsFormGroup"
                            [model]="getNext"></form-factory>
            </div>
          </div>
          <div [ngClass]="{'attribute__clinical-get-next': isShowChasePrioritySetting }" class="attribute-container">
            <div class="attribute-text">
              <form-factory [formGroup]="projectSettingsFormGroup" [model]="clinicalGetNext"
                            (onChange)="onChangeClinicalGetNext($event)"></form-factory>
            </div>
            <div *ngIf="isShowChasePrioritySetting && (this.isRiskProject || this.isIvaProject)" class="attribute-text dropdown risk-get-next">
              <form-factory [formGroup]="projectSettingsFormGroup" [model]="chasePrioritySettingInput"></form-factory>
            </div>
            <div *ngIf="isShowChasePrioritySetting && isHedisProject">
              <form-factory class="chase-priority-settings__priority-options" [formGroup]="projectSettingsFormGroup" [model]="hedisGetNextPriority"></form-factory>
              <div *ngIf="!projectHasMeasures">
                <form-factory class="chase-priority-settings__priority-options" [formGroup]="projectSettingsFormGroup" [model]="hedisGetNextPriorityPlaceholder"></form-factory>
                <p class="chase-priority-settings__measure-instructions">The project must have data in order to prioritize by measure.</p>
              </div>
              <div *ngIf="isPriorityMeasureSelected && projectHasMeasures">
                <p class="chase-priority-settings__measure-instructions">Rank the measures you wish to prioritize (1 is the highest priority).</p>
                <div class="chase-priority-settings__measure-grid" [ngStyle]="{'grid-template-columns': measureColumnStyle, 'max-width': maxWidthStyle}">
                  <div *ngFor="let measureInput of hedisMeasureInputs" class="measure-input">
                    <form-factory class="measure-input__uppercase"[formGroup]="hedisMeasureFormGroup" [model]="measureInput"></form-factory>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="attribute-container">
            <div class="attribute-text">
              <form-factory [formGroup]="projectSettingsFormGroup" [model]="dualRoleChaseAssignmentEnabled"></form-factory>
            </div>
          </div>
          <div class="attribute-container">
            <div class="attribute-text">
              <form-factory [formGroup]="projectSettingsFormGroup" [model]="memberCentricChaseRetrieval"></form-factory>
            </div>
          </div>
          <div class="subSectionHeader">
            <div class="attribute-text">
              <form-factory [formGroup]="projectSettingsFormGroup" [model]="vendorMatchingRequired"></form-factory>
            </div>
          </div>
          <div class="subSectionHeader" *ngIf="isMDPortalSettingEnabled">
            <div class="attribute-text">
              <form-factory [formGroup]="projectSettingsFormGroup" [model]="mdPortalRetrievalEnabled"></form-factory>
            </div>
          </div>
          <div class="subSectionHeader">
            <div class="attribute-text">
              <form-factory [formGroup]="projectSettingsFormGroup"
                            [model]="documentRequestRequiresApproval"></form-factory>
            </div>
          </div>

          <div class="subSectionHeader" *ngIf="shouldDisplayDocumentForInProcessChase">
            <div class="attribute-text">
              <form-factory [formGroup]="projectSettingsFormGroup" [model]="allowDocumentForInProcessChase"></form-factory>
            </div>
          </div>

          <div *ngIf="shouldDisplayChartChangeActionGrid">
            <div class="control control__header__label">
              Select Criteria For Chase Move Backs Due to New Pages
              <app-tool-tip [text]="chartChangeActionsTooltipText"></app-tool-tip>
            </div>
            <div class="chartChangeActionGrid">
              <app-chart-change-action-grid [(chartChangeActions)]="chartChangeActions"></app-chart-change-action-grid>
            </div>
          </div>

          <div class="subSectionHeader" *ngIf="!isProjectTypeMembercentric">
            <div class="attribute-text">
              <form-factory [formGroup]="projectSettingsFormGroup"
                            [model]="enableToAttachAdditionalDocInIntake"></form-factory>
            </div>
          </div>
          <div *ngIf="IsDocumentQAEnabled">
            <div class="packet-container packet-container__label">DOCUMENT QA</div>
            <div *ngFor="let item of projectConfigForm.get('MRQA')['controls']; let i=index; trackBy: trackByIndex"
                 class="form-control"
                 [formGroup]="item">
              <div class="label-input-container">
                <label class="label-container">% Fax:</label>
                <div>
                  <input type="text"
                         formControlName="Fax"
                         class="input percentage text-center">
                </div>
                <div class="errorMessage"
                     *ngIf="item.controls.Fax.invalid && item.controls.Fax.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="label-input-container">
                <label class="label-container">% Mail:</label>
                <div>
                  <input type="text"
                         formControlName="Mail"
                         class="input percentage text-center">
                </div>
                <div class="errorMessage"
                     *ngIf="item.controls.Mail.invalid && item.controls.Mail.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="label-input-container">
                <label class="label-container">% Emr:</label>
                <div>
                  <input type="text"
                         formControlName="Emr"
                         class="input percentage text-center">
                </div>
                <div class="errorMessage"
                     *ngIf="item.controls.Emr.invalid && item.controls.Emr.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="label-input-container">
                <label class="label-container">% Fieldtech:</label>
                <div>
                  <input type="text"
                         formControlName="Fieldtech"
                         class="input percentage text-center">
                </div>
                <div class="errorMessage"
                     *ngIf="item.controls.Fieldtech.invalid && item.controls.Fieldtech.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="label-input-container">
                <label class="label-container">% Provider:</label>
                <div>
                  <input type="text"
                         formControlName="Provider"
                         class="input percentage text-center">
                </div>
                <div class="errorMessage"
                     *ngIf="item.controls.Provider.invalid && item.controls.Provider.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="label-input-container">
                <label class="label-container">% Bulk Upload:</label>
                <div>
                  <input type="text"
                         formControlName="Approvalcenter"
                         class="input percentage text-center">
                </div>
                <div class="errorMessage"
                     *ngIf="item.controls.Approvalcenter.invalid && item.controls.Approvalcenter.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="label-input-container">
                <label class="label-container">% FTP:</label>
                <div>
                  <input type="text"
                         formControlName="Ftp"
                         class="input percentage text-center">
                </div>
                <div class="errorMessage"
                     *ngIf="item.controls.Ftp.invalid && item.controls.Ftp.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="label-input-container">
                <label class="label-container">% Intake:</label>
                <div>
                  <input type="text"
                         formControlName="Intake"
                         class="input percentage text-center">
                </div>
                <div class="errorMessage"
                     *ngIf="item.controls.Intake.invalid && item.controls.Intake.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="label-input-container">
                <label class="label-container">% MRR Upload:</label>
                <div>
                  <input type="text"
                         formControlName="Mrrupload"
                         class="input percentage text-center">
                </div>
                <div class="errorMessage"
                     *ngIf="item.controls.Mrrupload.invalid && item.controls.Mrrupload.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="label-input-container">
                <label class="label-container">% Pend Upload:</label>
                <div>
                  <input type="text"
                         formControlName="Pendupload"
                         class="input percentage text-center">
                </div>
                <div class="errorMessage"
                     *ngIf="item.controls.Pendupload.invalid && item.controls.Pendupload.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="label-input-container">
                <label class="label-container">% PSR Upload:</label>
                <div>
                  <input type="text"
                         formControlName="Psrupload"
                         class="input percentage text-center">
                </div>
                <div class="errorMessage"
                     *ngIf="item.controls.Psrupload.invalid && item.controls.Psrupload.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="label-input-container">
                <label class="label-container">% SMRC:</label>
                <div>
                  <input type="text"
                         formControlName="Smrc"
                         class="input percentage text-center">
                </div>
                <div class="errorMessage"
                     *ngIf="item.controls.Smrc.invalid && item.controls.Smrc.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="label-input-container">
                <label class="label-container">% Portal Upload:</label>
                <div>
                  <input type="text"
                         formControlName="PortalUpload"
                         class="input percentage text-center">
                </div>
                <div class="errorMessage"
                     *ngIf="item.controls.PortalUpload.invalid && item.controls.PortalUpload.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="label-input-container">
                <label class="label-container">% 3rd Party Upload:</label>
                <div>
                  <input type="text"
                         formControlName="ThirdPartyUpload"
                         class="input percentage text-center">
                </div>
                <div class="errorMessage"
                     *ngIf="item.controls.ThirdPartyUpload.invalid && item.controls.ThirdPartyUpload.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
            </div>
          </div>
          <div class="max-chase-count-input">
            <form-factory [formGroup]="projectSettingsFormGroup"
                          [model]="maxNumOfChasesPerPage"></form-factory>
          </div>
        </div>
      </div>
      <div formGroupName="slaSettingsFormGroup"
           class="sla-container"
           [hidden]="selectTab('sla')">
        <div class="form-group">
          <div class="attribute-container">
            <div class="attribute-text">
              <form-factory [formGroup]="projectSettingsFormGroup"
                            [model]="startDate"
                            (onChange)="projectEndDateChange()"></form-factory>
            </div>
          </div>
          <div class="attribute-container">
            <div class="attribute-text">
              <form-factory [formGroup]="projectSettingsFormGroup"
                            [model]="endDate"
                            (onChange)="projectEndDateChange()"></form-factory>
            </div>
          </div>
        </div>
        <div class="form-group">
          <h3>RETRIEVAL MILESTONES</h3>
          <div *ngFor="let type of milestoneRetrievalList; let i=index; trackBy: trackByIndex"
               class="form-control">
            <div formArrayName={{type}}
                 class="milestone-weekly-container">
              <div class="milestone-header">{{type | titlecase }} Milestones</div>
              <div class="milestone-weekly-item"
                   *ngFor="let item of projectConfigForm.get('slaSettingsFormGroup').get(type).controls; let i=index; trackBy: trackByIndex"
                   [formGroup]="item">
                <div class="attribute-text">
                  <input type="text"
                         class="input text-center"
                         [attr.id]="'Percentage'+i"
                         formControlName="Percentage">
                </div>
                <div>% by</div>
                <div class="select weeks">
                  <form-factory [formGroup]="item" [model]="Week"></form-factory>
                </div>
                <div class="delete-btn">
                  <app-button class="header-button"
                              text="x"
                              (onClick)="projectConfigForm.get('slaSettingsFormGroup').get(type).removeAt(i)">
                  </app-button>
                </div>
                <div class="errorMessage"
                     *ngIf="item.controls.Percentage.invalid && item.controls.Percentage.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="add-btn">
                <app-button class="header-button"
                            text="Add milestone"
                            (onClick)="projectConfigForm.get('slaSettingsFormGroup').get(type).push(buildEmptyListOfMileStones(type))">
                </app-button>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <h3>CLINICAL MILESTONES</h3>
          <div *ngFor="let type of milestoneClinicalList; let i=index; trackBy: trackByIndex"
               class="form-control">
            <div formArrayName={{type}}
                 class="milestone-weekly-container">
              <div class="milestone-header">{{type | titlecase }} Milestones</div>
              <div class="milestone-weekly-item"
                   *ngFor="let item of projectConfigForm.get('slaSettingsFormGroup').get(type).controls; let i=index; trackBy: trackByIndex"
                   [formGroup]="item">
                <div class="attribute-text">
                  <input type="text"
                         class=" input percentage text-center"
                         [attr.id]="'Percentage'+i"
                         formControlName="Percentage">
                </div>
                <div>% by</div>
                <div class="select weeks">
                  <form-factory [formGroup]="item" [model]="Week"></form-factory>
                </div>
                <div class="delete-btn">
                  <app-button class="header-button"
                              text="x"
                              (onClick)="projectConfigForm.get('slaSettingsFormGroup').get(type).removeAt(i)">
                  </app-button>
                </div>
                <div class="errorMessage"
                     *ngIf="item.controls.Percentage.invalid && item.controls.Percentage.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="add-btn">
                <app-button class="header-button"
                            text="Add milestone"
                            (onClick)="projectConfigForm.get('slaSettingsFormGroup').get(type).push(buildEmptyListOfMileStones(type))">
                </app-button>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="attribute-container no-header">
            <div class="attribute-text">
              <form-factory [formGroup]="projectSettingsFormGroup"
                            [model]="targetCoderQaScore"></form-factory>
            </div>
          </div>
        </div>
      </div>

      <div class="bot-container"
           [hidden]="selectTab('bot')">
           <div class="bot-container__title" *ngIf="!hedisFirstPassBotEnabled">
              <div [ngClass]="helperTextClass">
                {{riskBotHelperText}}
              </div>
            </div>
            <div class="attribute-text">
              <form-factory [formGroup]="projectSettingsFormGroup"
                            [model]="firstPassBotEnabled"
                            (onChange)="onChangeFirstPassBot()"></form-factory>

              <form-factory *ngIf="isFirstPassBotEnabled" [formGroup]="projectSettingsFormGroup"
              [model]="autoPendEmptyNlpResult"></form-factory>
            </div>

        <app-confirmation-modal
        [(visible)]="isEveForAbstractionPopupVisible"
        header="ATTENTION"
        acceptText="CONTINUE"
        rejectText="CANCEL"
        (onCancel)="onEveForAbstractionPopupCancel()">
          <div class="risk-bot-settings">
            <p class="risk-bot-settings__label">You are <span class="risk-bot-settings__bot-options">{{eveForAbstractionStateLabel}}</span> EVE for Abstraction for project</p>
            <p class="risk-bot-settings__label">{{projectName}}</p>
            <p class="risk-bot-settings__bot-text">{{eveForAbstractionText}}</p>
          </div>
        </app-confirmation-modal>

        <div  [hidden]="!riskFirstPassBotEnabled || !hedisFirstPassBotEnabled">
          <div class="chart-bot-overread-control">
            <div class="attribute-text">
              <form-factory [formGroup]="projectSettingsFormGroup"
                            [model]="totalChartsBotDataEnteredValue"></form-factory>
            </div>
          </div>

          <div class="firstPass-config-grid">
            <h3 class="title-container bot-config-header">Abstraction Configurations</h3>
            <div class="bot-container__title">
              <div class="bot-container__title--helper-text">
                When all results come back as Match and/or Partial-Match, we will automatically submit the chart for Overread, unless you specify AI Assist. If AI Assist is selected, your Abstractors will get a chance to review all EVE findings prior to submission.
              </div>
            </div>

            <app-basic-grid [configuration]="firstPassGridConfiguration" [resizableColumns]="false">
            </app-basic-grid>
            <div class="firstPass-config">
              <div *ngFor="let model of formModelsFirstPass; let j=index; trackBy: trackByIndex">
                <div [ngClass]="getfirstClass(j, formModelsFirstPass.length)">
                  <form-factory [formGroup]="formGroupAbstraction"
                                [model]="model" (onChange)="onSelectFirstPassChange($event, model)">
                  </form-factory>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>

      <div class="bot-container"
           [hidden]="selectTab('riskBot')">
        <div class="bot-container__title" *ngIf="!riskFirstPassBotEnabled">
          <div [ngClass]="helperTextClass">
            {{riskBotHelperText}}
          </div>
        </div>

        <div class="subSectionHeader">
          <div class="attribute-text">
            <form-factory [formGroup]="projectSettingsFormGroup"
                          [model]="firstPassCodingBotEnabled" (onChange)="onChangeRiskBot($event)"></form-factory>


                          <form-factory *ngIf="isFirstPassCodingBotEnabled" [formGroup]="projectSettingsFormGroup"
                          [model]="autoPendEmptyNlpResult"></form-factory>
                          <form-factory *ngIf="isCodingReviewModeDxV3" [formGroup]="riskFormGroup"
                          [model]="noNetNewOpportunityAutoPend"></form-factory>

          </div>
        </div>
        <app-modal [(visible)]="isRiskBotPopupVisible" header="ATTENTION" (onCancel)="onRiskBotPopupButtonClick('cancel')">
          <div class="risk-bot-settings">
            <p class="risk-bot-settings__label">You are <span class="risk-bot-settings__bot-options">{{selectedRiskBotOption}}</span> EVE First Pass Coding for project</p>
            <p class="risk-bot-settings__label">{{projectName}}</p>
            <p class="risk-bot-settings__bot-text">{{riskBotPopUpText}}</p>

          </div>
          <footer>
            <app-button classes="secondary" text="CONTINUE" (onClick)="onRiskBotPopupButtonClick('continue')"></app-button>
          </footer>
        </app-modal>
      </div>
      <div class="hedis-container"
           [hidden]="selectTab('hedis')">
        <div class="attribute-container">
          <div class="attribute-text dropdown">
            <form-factory [formGroup]="measureYearFormGroup"
                          [model]="measureYear"></form-factory>
          </div>
        </div>
        <div class="attribute-container">
          <div class="attribute-text dropdown">
            <form-factory [formGroup]="measureYearFormGroup"
                          [model]="reportingYear"></form-factory>
          </div>
        </div>
        <div class="subSectionHeader">
          <div class="attribute-text">
            <form-factory [formGroup]="measureYearFormGroup"
                          [model]="autocloseMemberCompliantChase"></form-factory>
          </div>
        </div>
        <div class="subSectionHeader">
          <div class="attribute-text">
            <form-factory [formGroup]="measureYearFormGroup"
                          [model]="autocloseMemberCompliantChaseForRecentEventMeasure"></form-factory>
          </div>
        </div>
        <div class="subSectionHeader">
          <div class="attribute-text">
            <form-factory [formGroup]="measureYearFormGroup"
                          [model]="nrcRequired"></form-factory>
          </div>
        </div>
        <div class="subSectionHeader">
          <div class="attribute-text">
            <form-factory [formGroup]="measureYearFormGroup"
                          [model]="demographicRequired"></form-factory>
          </div>
        </div>

        <div class="subSectionHeader">
          <div class="attribute-text">
            <form-factory [formGroup]="projectSettingsFormGroup"
                          [model]="dataEntryProviderConfirmationEnabled"></form-factory>
          </div>
        </div>
        <div class="subSectionHeader">
          <div class="attribute-text">
            <form-factory [formGroup]="projectSettingsFormGroup"
                          [model]="overreadFeedbackEnabled"></form-factory>
          </div>
        </div>
        <div class="subSectionHeader" *ngIf="isRetrievalTypeGap">
          <div class="attribute-text">
            <form-factory [formGroup]="projectSettingsFormGroup"
                          [model]="gapProviderOutreachEnabled"></form-factory>
          </div>
        </div>
        <div class="break-group">
          <div class="overread__content">
            <h3>OVERREAD</h3>

          </div>

          <div class="label-input-container">
            <div class="column-measure column">Measure</div>
            <div class="column">% Compliant</div>
            <div class="column">% Partial Compliance</div>
            <div class="column">% Non-Compliant</div>
            <div class="column">% Exclusion</div>
          </div>
          <div *ngFor="let item of projectConfigForm.get('Overread')['controls']; let i=index; trackBy: trackByIndex"
               class="form-control"
               [formGroup]="item">
            <div class="label-input-container">
              <label class="column-measure column label">{{item.get('MeasureCode').value}}</label>
              <div class="column label">
                <input type="text"
                       [attr.id]="'compliant'+i"
                       formControlName="Compliant"
                       class="input percentage text-center"
                       >
                <div class="errorMessage"
                     *ngIf="item.controls.Compliant.invalid && item.controls.Compliant.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="column label">
                <input type="text"
                       [attr.id]="'partiallycompliant'+i"
                       formControlName="PartiallyCompliant"
                       class="input percentage text-center"
                       >
                <div class="errorMessage"
                     *ngIf="item.controls.PartiallyCompliant.invalid && item.controls.PartiallyCompliant.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="column label">
                <input type="text"
                       [attr.id]="'noncompliant'+i"
                       formControlName="NonCompliant"
                       class="input percentage text-center"
                      >
                <div class="errorMessage"
                     *ngIf="item.controls.NonCompliant.invalid && item.controls.NonCompliant.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="column label">
                <input type="text"
                       [attr.id]="'noncompliantexclusion'+i"
                       formControlName="NonCompliantExclusion"
                       class="input percentage text-center"
                    >
                <div class="errorMessage"
                     *ngIf="item.controls.NonCompliantExclusion.invalid && item.controls.NonCompliantExclusion.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="break-group">
          <h3>OVERREAD 2</h3>
          <div class="label-input-container">
            <div class="column-measure column">Measure</div>
            <div class="column">% Compliant</div>
            <div class="column">% Partial Compliance</div>
            <div class="column">% Non-Compliant</div>
            <div class="column">% Exclusion</div>
          </div>
          <div *ngFor="let item of projectConfigForm.get('Overread2')['controls']; let i=index; trackBy: trackByIndex"
               class="form-control"
               [formGroup]="item">
            <div class="label-input-container">
              <label class="column-measure column label">{{item.get('MeasureCode').value}}</label>
              <div class="column label">
                <input type="text"
                       [attr.id]="'compliant'+i"
                       formControlName="Compliant"
                       class="input percentage text-center">
                <div class="errorMessage"
                     *ngIf="item.controls.Compliant.invalid && item.controls.Compliant.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="column label">
                <input type="text"
                       [attr.id]="'partiallycompliant'+i"
                       formControlName="PartiallyCompliant"
                       class="input percentage text-center">
                <div class="errorMessage"
                     *ngIf="item.controls.PartiallyCompliant.invalid && item.controls.PartiallyCompliant.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="column label">
                <input type="text"
                       [attr.id]="'noncompliant'+i"
                       formControlName="NonCompliant"
                       class="input percentage text-center">
                <div class="errorMessage"
                     *ngIf="item.controls.NonCompliant.invalid && item.controls.NonCompliant.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
              <div class="column label">
                <input type="text"
                       [attr.id]="'noncompliantexclusion'+i"
                       formControlName="NonCompliantExclusion"
                       class="input percentage text-center">
                <div class="errorMessage"
                     *ngIf="item.controls.NonCompliantExclusion.invalid && item.controls.NonCompliantExclusion.touched">
                  * Percentage must be between 0 - 100
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="risk" [hidden]="selectTab('risk')">
        <div>
          <div class="attribute-container" *ngIf="isIvaProject">
            <div class="attribute-text">
              <form-factory [formGroup]="riskFormGroup" [model]="hiosId"></form-factory>
            </div>
          </div>
          <div class="attribute-container">
            <div class="attribute-text">
              <form-factory [formGroup]="riskFormGroup" [model]="reviewPeriodFromDate"></form-factory>
            </div>
          </div>
          <div class="attribute-container">
            <div class="attribute-text">
              <form-factory [formGroup]="riskFormGroup" [model]="reviewPeriodThruDate"></form-factory>
            </div>
          </div>
          <div class="attribute-container">
            <div class="attribute-text dropdown">
              <form-factory [formGroup]="riskFormGroup" [model]="documentReviewLevel"></form-factory>
            </div>
          </div>
          <div *ngIf="!isIvaProject" class="attribute-container">

            <div class="attribute-text dropdown d-flex">
              <form-factory [formGroup]="riskFormGroup" [model]="codingReviewModeSelection" ></form-factory>
              <app-tool-tip [text]="getTooltipText()" tooltipPosition="right" ></app-tool-tip>
            </div>
            <div class="attribute-text dropdown">
              <form-factory [formGroup]="riskFormGroup" [model]="codingLevel" (onChange)="onCodingLevelChange($event)"></form-factory>
            </div>
          </div>
          <div class="attribute-container">
            <div class="attribute-text" [hidden]="!showCodingOptimizationMode">
              <form-factory [formGroup]="riskFormGroup" [model]="codingOptimizationMode"></form-factory>
            </div>
          </div>
          <div class="attribute-container">
            <div class="attribute-text dropdown">
              <form-factory [formGroup]="riskFormGroup" [model]="hcc"></form-factory>
            </div>
            <div class="attribute-text dropdown" *ngIf="isHcc2Selected">
              <form-factory [formGroup]="riskFormGroup" [model]="hcc2"></form-factory>
            </div>
          </div>
          <div class="attribute-container">
            <div class="attribute-text dropdown" *ngIf="showRxHccInput">
              <form-factory [formGroup]="riskFormGroup" [model]="rxHcc"></form-factory>
            </div>
          </div>
          <div class="attribute-text" *ngIf="!isCodingReviewModeDxV3">
            <form-factory [formGroup]="projectSettingsFormGroup"
                          [model]="overreadFeedbackEnabled"></form-factory>
          </div>
        </div>
        <div *ngIf="isRiskProject || isIvaProject" class="overread-settings">
          <div class="overread-settings__label">OVERREAD</div>
          <div>
            <div class="overread-settings__row overread-settings__row--measure-code"></div>
            <div class="overread-settings__row overread-settings__label overread-settings__row--inputs text-center">Deficient</div>
            <div class="overread-settings__row overread-settings__label overread-settings__row--inputs text-center">Non-Deficient</div>
          </div>
          <div *ngFor="let item of projectConfigForm.get('Overread')['controls']; let i=index; trackBy: trackByIndex" [formGroup]="item">
            <div class="overread-settings__row overread-settings__label overread-settings__row--measure-code">{{item.controls.MeasureCode.value}}</div>
            <div class="overread-settings__row overread-settings__label overread-settings__row--inputs">
              <input type="text" [attr.id]="'deficientchases'+i"
                     formControlName="Deficientchases" class="control__input control__input--textbox text-center percentage"
                     (blur)="checkDeficientChases('Overread', i)">
              <div class="errorMessage" *ngIf="item.controls.Deficientchases.invalid && item.controls.Deficientchases.touched">
                * Percentage must be between 0 - 100
              </div>
            </div>
            <div  class="overread-settings__row overread-settings__label overread-settings__row--inputs text-center"><input type="text" [attr.id]="'submittablechases'+i"
                     formControlName="Submittablechases" class="control__input control__input--textbox text-center percentage">
              <div class="errorMessage"
                   *ngIf="item.controls.Submittablechases.invalid && item.controls.Submittablechases.touched">
                * Percentage must be between 0 - 100
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isRiskProject || isIvaProject" class="overread-settings">
          <div class="overread-settings__label">OVERREAD 2</div>
          <div>
            <div class="overread-settings__row overread-settings__row--measure-code"></div>
            <div class="overread-settings__row overread-settings__label overread-settings__row--inputs text-center">Deficient</div>
            <div class="overread-settings__row overread-settings__label overread-settings__row--inputs text-center">
              Non-Deficient</div>
          </div>
          <div *ngFor="let item of projectConfigForm.get('Overread2')['controls']; let i=index; trackBy: trackByIndex" [formGroup]="item">
            <div class="overread-settings__row  overread-settings__label overread-settings__row--measure-code">
              {{item.controls.MeasureCode.value}}</div>
            <div class="overread-settings__row overread-settings__label overread-settings__row--inputs"><input type="text" [attr.id]="'deficientchases'+i"
                     formControlName="Deficientchases" class="control__input control__input--textbox text-center percentage"
                     (blur)="checkDeficientChases('Overread2', i)">
              <div class="errorMessage" *ngIf="item.controls.Deficientchases.invalid && item.controls.Deficientchases.touched">
                * Percentage must be between 0 - 100
              </div>
            </div>
            <div class="overread-settings__row overread-settings__label overread-settings__row--inputs text-center"><input type="text"
                     [attr.id]="'submittablechases'+i" formControlName="Submittablechases"
                     class="control__input control__input--textbox text-center percentage">
              <div class="errorMessage"
                   *ngIf="item.controls.Submittablechases.invalid && item.controls.Submittablechases.touched">
                * Percentage must be between 0 - 100
              </div>
            </div>
          </div>
          <div class="disclaimer-text text__danger">
            * If deficient chase values are set to anything less than 100%, some pursuits will not get generated and there's a
            chance invalid chases will be submitted to delivery.
          </div>
        </div>
        <div class="ui-g-12">
          <div class="attribute-text">
            <form-factory [formGroup]="riskFormGroup" [model]="reviewAdminEncountersAndDiagnoses"></form-factory>
          </div>
        </div>
        <ng-container *ngIf="isCodingReviewModeDxV3">
          <div class="ui-g-12" >
            <div class="ui-g-5 dynamic-validations-container">
              <h3>HCC Optimization Preferences</h3>
              <br>
              <h4>Dynamic Validations</h4>
              <p class="secondary-text-color">Coder validated ICD codes that map to hierarchical HCCs are automatically marked as "Known". You can customize the minimum number of validations required</p>
              <div class="dynamic-validations-container--sub-container">
                <form-factory class="dynamic-validations-container--switch" (onChange)="onDynamicHccChange($event)" [formGroup]="riskFormGroup" [model]="codingSuppressDxForConfirmedHcc"></form-factory>
                <span class="dynamic-validations-container--text">Minimum required validation</span>
                <form-factory class="dynamic-validations-container--dropdown" [formGroup]="riskFormGroup" [model]="hccDynamicSuppression"></form-factory>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="ui-g-12" *ngIf="!isCodingReviewModeDxV3">
          <div class="attribute-text">
            <form-factory [formGroup]="riskFormGroup" [model]="serviceProviderRequired"></form-factory>
          </div>
        </div>
        <div class="ui-g-12">
          <div class="attribute-text" [ngClass]="{onloadDisabled: onLoadDisableVRCANDServiceType}">
            <form-factory [formGroup]="riskFormGroup" [model]="validationReasonCodeRequired"></form-factory>
            <form-factory *ngIf="!isCodingReviewModeDxV3" [formGroup]="riskFormGroup" [model]="encounterTypeRequired"></form-factory>
          </div>
        </div>
        <div class="ui-g-12"  >
          <div class="attribute-text">
            <form-factory [formGroup]="riskFormGroup" [model]="hccCardAdditionEnabled"></form-factory>
          </div>
        </div>
        <div class="ui-g-12" >
          <div class="attribute-text">
            <form-factory [formGroup]="riskFormGroup" [model]="isEveOnlyCardsValidationRequired"></form-factory>
          </div>
        </div>
        <div class="ui-g-12" *ngIf="isIvaProject">
          <div class="attribute-text">
            <div class="section">
              <span class="iva-mapping-document-header">MAPPING DOCUMENTS</span>

              <app-iva-mapping-document [projectMappingDocumentAttribute]="enrMappingDocumentAttribute"
                                        (uploadDeleteIVAFile)="uploadDeleteIVAFile($event)" [documentType]="enrMappingText"></app-iva-mapping-document>

              <app-iva-mapping-document [projectMappingDocumentAttribute]="enrCrossWalkAttribute"
                                        (uploadDeleteIVAFile)="uploadDeleteIVAFile($event)" [documentType]="enrCrosswalkText"></app-iva-mapping-document>

              <app-iva-mapping-document [projectMappingDocumentAttribute]="rxcMappingDocumentAttribute"
                                        (uploadDeleteIVAFile)="uploadDeleteIVAFile($event)" [documentType]="rxcMappingText"></app-iva-mapping-document>

              <app-iva-mapping-document [projectMappingDocumentAttribute]="rxcCrossWalkAttribute"
                                        (uploadDeleteIVAFile)="uploadDeleteIVAFile($event)" [documentType]="rxcCrosswalkText"></app-iva-mapping-document>

              <app-iva-mapping-document [projectMappingDocumentAttribute]="ivaMissingMedicalAttribute"
                                        (uploadDeleteIVAFile)="uploadDeleteIVAFile($event)" [documentType]="ivaMissingMedicalText"></app-iva-mapping-document>

              <span class="iva-mapping-document-header">FILE MANIFESTS</span>
              <span class="filename-example">Filename example: 123456_File Manifest Pkg2_123456.csv</span>
              <app-iva-mapping-document fileNamePattern=".*_File Manifest Pkg(1|2|3)_.*.csv" fileTypes=".csv" [projectMappingDocumentAttribute]="ivaManifestAttribute"
                (uploadDeleteIVAFile)="uploadDeleteIVAFile($event)" [documentType]="ivaManifestText"></app-iva-mapping-document>
              <app-iva-mapping-document fileNamePattern=".*_File Manifest Pkg(1|2|3)_.*.csv" fileTypes=".csv" [projectMappingDocumentAttribute]="ivaManifestAttribute2"
                (uploadDeleteIVAFile)="uploadDeleteIVAFile($event)" [documentType]="ivaManifest2Text"></app-iva-mapping-document>
              <app-iva-mapping-document fileNamePattern=".*_File Manifest Pkg(1|2|3)_.*.csv" fileTypes=".csv" [projectMappingDocumentAttribute]="ivaManifestAttribute3"
                                        (uploadDeleteIVAFile)="uploadDeleteIVAFile($event)" [documentType]="ivaManifest3Text"></app-iva-mapping-document>
              <span class="iva-mapping-document-header">SVA SUBSAMPLE REPORT</span>
              <span class="filename-example">Filename example: 123456-RADV_SVA_Subsample.csv</span>
              <app-iva-mapping-document fileNamePattern=".*-RADV_SVA_Subsample.*.csv" fileTypes=".csv" [projectMappingDocumentAttribute]="ivaSubsampleAttribute"
                (uploadDeleteIVAFile)="uploadDeleteIVAFile($event)" [documentType]="ivaSubsampleText"></app-iva-mapping-document>
            </div>
          </div>
        </div>



      </div>
      <div class="packet-container"
           [hidden]="selectTab('packet')">
        <div class="packet-container__label">PROVIDER PACKET CONTENTS</div>
        <div class="section">
          <label class="service-org-label">Upload Logo</label>
          <div class="logo-container">
            <span *ngIf="!hasLogo; else image" class="logo-container__text">Logo Preview</span>
            <ng-template #image><img class="logo-container__image" [attr.src]="logoUrl"/></ng-template>
          </div>
          <div class="file-container">
            <div class="file-upload-container"><span [ngClass]="fileUploadLabel">{{fileName}}</span></div>
            <div class="file-upload-sub-container">
              <p-fileUpload #fileUpload (onSelect)="onLogoSelect($event)" [maxFileSize]="maxFileSizeForProviderPacketLogoUploadInBytes" (onRemove)="onLogoRemove()" accept=".png,.jpg,.jpeg" [multiple]="false" chooseLabel="Browse" [showUploadButton]="false" [showCancelButton]="false">
              </p-fileUpload>
            </div>
          </div>
          <div class="ui-fileupload-row" *ngIf="isServiceOrgLogoAvailable">
            <div class="file-name-container">{{fileName}}</div>
            <div>{{size}}</div>
            <div class="delete-btn">
              <app-button class="remove-button"
                          icon="times"
                          (onClick)="onLogoDelete(fileName)">
              </app-button>
            </div>

          </div>
        </div>
        <div class="section">
            <form-factory [formGroup]="providerPacketSettingsFormGroup"
                          [model]="addressInsertRequired"></form-factory>
          <form-factory [formGroup]="providerPacketSettingsFormGroup" [model]="coverLetterRequired"></form-factory>
          <div *ngIf="coverLetterToggle" class="section">
            <div class="section">
              <form-factory class="form-container form-container--inline" [formGroup]="providerPacketFromGroup" [model]="coverLetterProjectListInput"></form-factory>
              <span class="packet-button-container">
                <app-button text="APPLY"
                            [disabled]="!coverLetterProjectSelected"
                            (onClick)="copyCoverLetterSettings()"></app-button>
              </span>
            </div>
            <div class="section">
              <div class="packet-container__form-label">COVER LETTER HEADER LABELS</div>
              <div *ngFor="let item of projectConfigForm.get('CoverLetterHeaders').controls; let i=index; trackBy: trackByIndex"
                   class="form-container--extended"
                   [formGroup]="item">
                <div class="header-section">
                  <div class="header-section--center">
                    <label class="checkbox-container">
                      {{item.get('Description').value | titlecase }}
                      <input type="checkbox"
                             formControlName="isrequired">
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div>
                    <input type="text"
                           formControlName="Value"
                           class="input">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-container">
              <form-factory [formGroup]="providerPacketFromGroup"
                            [model]="coverLetterTemplate"></form-factory>
            </div>
            <div class="section"
                 [hidden]="hideEditor">
              <div>COVER LETTER BODY</div>
              <div *ngFor="let item of projectConfigForm.get('CoverLetterBody').controls; let i=index; trackBy: trackByIndex"
                   class="template-container"
                   [formGroup]="item">
                <p-editor formControlName="Value"
                          [style]="{'height':'320px'}"
                          (onSelectionChange)="coverLetterSelectionChange($event)">
                  <p-header>
                    <span class="ql-formats">
                      <select class="ql-header">
                        <option value="1">Heading</option>
                        <option value="2">Subheading</option>
                        <option selected>Normal</option>
                      </select>
                    </span>
                    <span class="ql-formats">
                      <button class="ql-bold"
                              aria-label="1"></button>
                      <button class="ql-italic"
                              aria-label="Italic"></button>
                      <button class="ql-underline"
                              aria-label="Underline"></button>
                    </span>
                    <span class="ql-formats">
                      <select class="ql-align">
                        <option selected></option>
                        <option value="center"></option>
                        <option value="right"></option>
                        <option value="justify"></option>
                      </select>
                    </span>
                  </p-header>
                </p-editor>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <form-factory [formGroup]="providerPacketSettingsFormGroup" [model]="authLetterRequired"></form-factory>
          <div *ngIf="authLetterToggle" class="section">
            <div class="section file-upload">
              <p-fileUpload #fileUpload
                            name="file[]"
                            (onSelect)="onFileSelect($event)"
                            (onRemove)="onFileRemove($event)"
                            (uploadHandler)="uploadDocument($event, fileUpload)"
                            customUpload="true"
                            accept=".pdf"
                            maxFileSize="1000000000"
                            chooseLabel="Upload"
                            uploadLabel="Submit"
                            [showUploadButton]="true">
              </p-fileUpload>
            </div>
            <span *ngIf="isFileUploaded">
              <b>Uploaded File :</b> {{fileDetail?.fileName}} &nbsp;&nbsp; {{fileDetail?.fileSize}}&nbsp;&nbsp; {{fileDetail?.uploadDateTime}}&nbsp;&nbsp;
              <app-icon [iconName]="iconName" class="fileIcon" (click)="downloadFile()"></app-icon>
            </span>
          </div>
        </div>
        <div class="section">
          <form-factory [formGroup]="providerPacketSettingsFormGroup" [model]="memberListRequired"></form-factory>
          <div *ngIf="memberListToggle" class="section">
            <div class="section">
              <form-factory class="form-container form-container--inline" [formGroup]="providerPacketFromGroup" [model]="memberListProjectListInput"></form-factory>
              <span class="packet-button-container">
                <app-button text="APPLY"
                            [disabled]="!memberListProjectSelected"
                            (onClick)="copyMemberListSettings()"></app-button>
              </span>
            </div>
            <div class="section">
              <div class="packet-container__form-label">MEMBER PULL LIST HEADER LABELS</div>
              <div *ngFor="let item of projectConfigForm.get('MemberListHeaders').controls; let i=index; trackBy: trackByIndex"
                   class="form-container--extended"
                   [formGroup]="item">
                <div class="header-section">
                  <div class="header-section--center">
                    <label class="checkbox-container">
                      {{item.get('Description').value | titlecase }}
                      <input type="checkbox"
                             formControlName="isrequired">
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div>
                    <input type="text"
                           formControlName="Value"
                           class="input">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div [hidden]="!isRetrievalTypeGap">
          <form-factory [formGroup]="providerPacketSettingsFormGroup" [model]="providerPackageCheckIncludeDocumentRequestForGap"></form-factory>
        </div>
        <div class="section" *ngIf="!isRetrievalTypeGap">
          <form-factory [formGroup]="providerPacketSettingsFormGroup" [model]="chaseLetterRequired"></form-factory>
          <div *ngIf="chaseLetterToggle" class="section">
            <div class="section">
              <form-factory class="form-container form-container--inline" [formGroup]="providerPacketFromGroup" [model]="chaseLetterProjectListInput"></form-factory>
              <span class="packet-button-container">
                <app-button text="APPLY"
                            [disabled]="!chaseLetterProjectSelected"
                            (onClick)="copyChaseLetterSettings()"></app-button>
              </span>
            </div>
            <div class="section visually-hidden">
              <div>Template</div>
              <div class="milestone-weekly-item"
                   *ngFor="let item of projectConfigForm.get('ChaseLetterTemplate').controls; let i=index; trackBy: trackByIndex"
                   [formGroup]="item">
                <div class="select projects">
                  <select formControlName="Value">
                    <option [ngValue]="null"
                            disabled
                            selected>
                      Select template...
                    </option>
                    <option *ngFor="let template of chaseLetterTemplateList"
                            [ngValue]="template.value">
                      {{template.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="section">
              <form-factory class="form-container form-container--inline" [formGroup]="providerPacketFromGroup" [model]="measureListInput"></form-factory>
            </div>
            <div class="sla-container" formGroupName="RequiredDocsPerMeasure" *ngIf="isDocumentHasTimeFrame">
              <div> DOCUMENT REQUESTS   <span class="time-frame-margin">TIME FRAME</span> </div>
              <div class="document-request-item ">
                <div *ngFor="let item of projectConfigForm.get('RequiredDocsPerMeasure').controls; let i=index; trackBy: trackByIndex"
                     [formGroup]="item">
                  <div class="milestone-weekly-item" *ngIf="this.selectedMeasureCode === item.controls.MeasureCode.value">
                    <input formControlName="Documents" class="input request-timeline-input"
                           *ngIf="this.selectedMeasureCode === item.controls.MeasureCode.value">

                    <div *ngIf="this.selectedMeasureCode === item.controls.MeasureCode.value">
                      <form-factory [formGroup]="item"
                                    [model]="timeFrameFrom" class="timeFrame-control-input"></form-factory>
                    </div>
                    <div class="time-frame-range"><input class="timeFrameMiddleText" readonly="true" formControlName="timeframeMiddleText" /></div>
                    <div *ngIf="this.selectedMeasureCode === item.controls.MeasureCode.value">
                      <form-factory [formGroup]="item"
                                    [model]="timeFrameTo" class="timeFrame-control-input" (onChange)="onTimeFrameToChange($event.value, item)"></form-factory>
                    </div>
                    <div class="add-btn btn-remove-padding">
                      <div (click)="projectConfigForm.get('RequiredDocsPerMeasure').removeAt(i)">
                        <app-button class="btn-remove"
                                    [ngClass]="getRemoveIcon()"
                                    *ngIf="this.selectedMeasureCode === item.controls.MeasureCode.value">

                        </app-button>

                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="add-btn"   (click)="projectConfigForm.get('RequiredDocsPerMeasure').push(buildDynamicListOfMeasureCodes(this.selectedMeasureCode,Documents,timeFrameFrom,timeFrameTo))">
                <div [ngClass]="getClassForPlusIcon()">
                  <app-icon [iconName]="getTagIcon()" [iconStyle]="getTagIcon()"></app-icon>
                </div>
                <app-button class="header-button btn-add-request"
                            styleClass="request-line-lettercase"
                            text="Add New Document Request Line">
                </app-button>
              </div>
            </div>
            <div class="section" *ngIf="!isDocumentHasTimeFrame">
              <div>DOCUMENT DESCRIPTION</div>
              <div *ngFor="let item of projectConfigForm.get('RequiredDocsPerMeasure').controls; let i=index; trackBy: trackByIndex"
                   class="template-container"
                   [formGroup]="item">
                <textarea formControlName="Documents"
                          cols="100"
                          rows="10"
                          *ngIf="this.selectedMeasureCode === item.controls.MeasureCode.value"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="invoice-container"
           [hidden]="selectTab('invoice')">
        <div class="form-group">
          <p class="information-text">Your Service Organization was configured to work with the following 3rd party Vendors. Below you have the option to review, accept, or deny the cost for each chart type.</p>
          <h3>INVOICE CONFIGURATION</h3>
          <div class="attribute-container">
            <div class="attribute-text">
              <form-factory [formGroup]="projectSettingsFormGroup"
                            [model]="vendorInvoiceCODValue"></form-factory>
            </div>
            <div class="invoice-list">
              <app-server-grid [configuration]="invoiceGridConfiguration"
                               [(request)]="invoiceGridRequest"
                               [refresh]="refreshGrid">

                <ng-template #invoiceColumn let-rowData>
                  <app-project-invoice-config [vendorDetail]="rowData" (onUpdate)="refreshGrid.emit()"></app-project-invoice-config>
                </ng-template>
              </app-server-grid>
            </div>
          </div>
        </div>
      </div>

      <div class="reporting-container"
           [hidden]="selectTab('reporting')">
        <div class="attribute-container">
          <div class="attribute-text">
            <form-factory [formGroup]="reportingFormGroup"
                          [model]="expectedChaseCount"></form-factory>
          </div>
        </div>
        <div class="attribute-container">
          <div class="attribute-text">
            <form-factory [formGroup]="reportingFormGroup"
                          [model]="expectedSampleFeedReceiptDate"></form-factory>
          </div>
        </div>
        <div class="attribute-container">
          <div class="attribute-text">
            <form-factory [formGroup]="reportingFormGroup"
                          [model]="targetLoadDate"></form-factory>
          </div>
        </div>
        <div class="attribute-container">
          <div class="attribute-text">
            <form-factory [formGroup]="reportingFormGroup"
                          [model]="initialSampleFeedReceiptDate"></form-factory>
          </div>
        </div>
        <div class="attribute-container">
          <div class="attribute-text">
            <form-factory [formGroup]="reportingFormGroup"
                          [model]="finalSampleFeedReceiptDate"></form-factory>
          </div>
        </div>
      </div>
      <div class="datatransfer-container"
           [hidden]="selectTab('dataExtract')">
        <div class="ui-md-4">
          <div class="attribute-text dropdown">
            <form-factory [formGroup]="dataTransferFtpFormGroup"
                          [model]="dataExtractType"
                          (onChange)="onChangeDataExtractType($event)"></form-factory>
          </div>
        </div>

        <div class="ui-md-4">
          <div class="attribute-text dropdown">
            <form-factory [formGroup]="dataTransferFtpFormGroup"
                          [model]="dataExtractDelimiter"></form-factory>
          </div>
        </div>

        <div class="ui-md-4">
          <div class="attribute-text dropdown">
            <form-factory [formGroup]="dataTransferFtpFormGroup"
                          [model]="dataExtractIncludeHeader"></form-factory>
          </div>
        </div>

        <div class="section">
          <div class="data-extract-schedule-container section">
            <div class="attribute-container">
              <div class="attribute-text data-extractor-wrap">
                <form-factory [formGroup]="dataTransferSchedulerFormGroup"
                              [model]="dataTransferEnableSchedule"
                              (onChange)="onChangeSchedulerSettings($event)"></form-factory>
              </div>
            </div>

            <div class="scheduler-settings-content-container section">
              <div class="ui-md-4">
                <div class="attribute-text selectAllBtn" [ngClass]="{disabled: isWeekDaysDisabled}">
                  <form-factory [formGroup]="dataTransferSchedulerFormGroup"
                                [model]="number"></form-factory>
                </div>
              </div>

              <div class="time-zone-wrapper">
                <div class="attribute-container">
                  <div class="attribute-text dropdown">
                    <form-factory [formGroup]="dataTransferSchedulerFormGroup"
                                  [model]="hour"></form-factory>
                  </div>
                </div>

                <div class="attribute-container">
                  <div class="attribute-text dropdown">
                    <form-factory [formGroup]="dataTransferSchedulerFormGroup"
                                  [model]="period"></form-factory>
                  </div>
                </div>

                <div class="attribute-inside-container">
                  <div class="attribute-text dropdown time-zone">
                    <form-factory [formGroup]="dataTransferSchedulerFormGroup"
                                  [model]="timezone"></form-factory>
                  </div>
                </div>

                <div class="attribute-inside-container">
                  <div class="attribute-textdate">
                    <form-factory [formGroup]="dataTransferSchedulerFormGroup" (onBlur)="validateDate()"
                                  [model]="schedulerEndDate"></form-factory>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="checklist-container"
           [hidden]="selectTab('checklist')">
        <div class="attribute-container">
          <app-tab-menu orientation="top">
            <div *ngFor="let list of chaseSubmissionItem; index as i; trackBy: trackByIndex">
              <app-tab-panel header={{list.workflowStatus}}>
                <div class="project-checklist">
                  <div class="project-checklist__title">Create a custom checklist to appear at the end of the data entry screen and a drop-down menu for entering data entry checklist results. Click "Add new" to begin.</div>
                  <div class="project-checklist__list-header">Data Entry Checklist Items</div>
                  <app-drag-list [(value)]="list.items" (valueChange)="onChaseSubmissionValueChange($event)"></app-drag-list>
                </div>
              </app-tab-panel>
            </div>
          </app-tab-menu>
        </div>
      </div>

      <div class="save-button-container text-right">
        <app-button *ngIf="isProviderTabSelected"
                    class="header-button"
                    text="PREVIEW"
                    [disabled]="!enableProviderPacket"
                    (onClick)="generatePreviewPacket()"></app-button>
        <app-button class="header-button"
                    text="SAVE"
                    (onClick)="saveProjectSettings('button')"
                    [disabled]="(projectConfigForm.invalid && projectConfigForm.touched) || !enableProjectConfigSave"></app-button>
      </div>
    </form>
  </div>
</app-detail-main-template>
<div class="ui-g-6 ui-md-6 ui-lg-6 or-percentage-container">
  <app-modal [(visible)]="isDeficientChasePopupVisible" header="{{headerText}} Percentages">
    <div>
      <p class="label">If the deficient chase values are set to anything less than 100%,some pursuits will not be generated and there's a chance invalid chases will be submitted to delivery.</p>
    </div>
    <footer>
      <app-button class="header-button" text="OK" (onClick)="onClosePopup()"></app-button>
    </footer>
  </app-modal>
</div>

<app-confirmation-modal [(visible)]="showConfirmChangeNavigation"
                        header="WARNING"
                        (onYes)="continueNavigation()"
                        (onCancel)="cancelNavigation()"
                        acceptText="DON'T SAVE"
                        rejectText="CANCEL">
  You have unsaved changes. Are you sure you want to leave this page?
  <br />
  <footer>
    <app-button classes="secondary" text="SAVE CHANGES" (onClick)="saveAndContinueNavigation()">
    </app-button>
  </footer>
</app-confirmation-modal>
